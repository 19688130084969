<template>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-sm-12">
        <gradient-box>
          <span>أهلاً</span> <span>{{ $store.getters.firstName }}</span>
          <template #paragraph>
            الاردنية الاولى للمزادات
          </template>
        </gradient-box>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <routed-card
          bg="bg-orange"
          title="مزاداتي"
          icon="fa fa-gavel"
          :route="{ name: 'client.auctions.following' }"
        ></routed-card>
      </div>
      <div class="col-md-4">
        <routed-card
          bg="bg-green"
          title="بياناتي"
          icon="fas fa-user-cog"
          :route="{ name: 'user.profile' }"
        ></routed-card>
      </div>
      <div class="col-md-4">
        <routed-card
          bg="bg-red"
          title="التأمين"
          icon="fa fa-car"
          :route="{ name: 'user.insurance' }"
        ></routed-card>
      </div>
    </div>
  </div>
</template>

<script>
import GradientBox from "@/components/UI/GradientBox";
import RoutedCard from "@/components/UI/RoutedCard";

export default {
  components: { RoutedCard, GradientBox },
};
</script>
